import * as actionTypes from '../actions';
import { actions } from 'react-table';

const initialState = {
	selectedFilterValues: {
		project_name: [],
		project: [],
		vendor: [],
		grpStr: [],
		po: [],
		structure: [],
		pl: [],
		mark: [],
		pos: [],
		ident: [],
		vendorIds: [],
		grpStrIds: [],
		poIds: [],
		structureIds: [],
		plIds: [],
		markIds: [],
		posIds: [],
		identIds: [],
		filterType: {
			name: 'All',
			value: null,
			activeBtn: true,
		},
		materialStatus: null,
		extraStatus: null,
		completedStatus:null,
		statusData: {
			released: false,
			notReleased: false,
			shipped: false,
			notShipped: false,
			onsite: false,
			notOnsite: false,
			withExtra: false,
			withoutExtra: true,
		},
	},
	projectList: [],
	vendorList: [],
	grpStrList: [],
	poList: [],
	structureList: [],
	plList: [],
	markList: [],
	posList: [],
	identList: [],
	vendorLoading: true,
	grpStrLoading: true,
	poLoading: true,
	structureLoading: true,
	plLoading: true,
	markLoading: true,
	posLoading: true,
	identLoading: true,
	isFilterChanged: false,
	projectCode:null,
	weightUOM: null,
	dimUOM: null,
	volUOM: null,
	weightDec: 0,
	dimDec: 0,
	currencyUOM: null,
	defCurrUOM: null,
	currConvRate: '1.00',
	currId: null,
	isCurrChanged: false,
	currList: [],
	zeroPipingVendor: false,
	isVendorUnchecked: false,
	useSelValues: false,
	selectedTab: 'dashboard',
	prevSelValue: null,
	chartData: null,
	chartFilter: null,
	isClear: false,
	statusCompleted: true,
	loadedProject: null,
	loadedPO: null,
	status: {
		data: [],
		total: 0,
	},
	filterSearchValue: {
		Project: '',
		Vendor: '',
		'Purchase Order': '',
		'Group Structure': '',
		Structure: '',
		'Packing List': '',
		Mark: '',
		'Po Position': '',
		'Ident Code': '',
	},
	filterObj: {},
};

const DBReducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.DB_SET_TO_INITIALSTATE:
			state = {
				selectedFilterValues: {
					project_name: [],
					project: [],
					vendor: [],
					grpStr: [],
					po: [],
					structure: [],
					pl: [],
					mark: [],
					pos: [],
					ident: [],
					vendorIds: [],
					grpStrIds: [],
					poIds: [],
					structureIds: [],
					plIds: [],
					markIds: [],
					posIds: [],
					identIds: [],
					filterType: {
						name: 'All',
						value: null,
						activeBtn: true,
					},
					materialStatus: null,
					extraStatus: null,
					completedStatus:null,
					statusData: {
						released: false,
						notReleased: false,
						shipped: false,
						notShipped: false,
						onsite: false,
						notOnsite: false,
						withExtra: false,
						withoutExtra: true,
					},
				},
				projectList: [],
				vendorList: [],
				grpStrList: [],
				poList: [],
				structureList: [],
				plList: [],
				markList: [],
				posList: [],
				identList: [],
				vendorLoading: true,
				grpStrLoading: true,
				poLoading: true,
				structureLoading: true,
				plLoading: true,
				markLoading: true,
				posLoading: true,
				identLoading: true,
				projectCode:null,
				weightUOM: null,
				dimUOM: null,
				volUOM: null,
				currencyUOM: null,
				defCurrUOM: null,
				isCurrChanged: false,
				currList: [],
				zeroPipingVendor: false,
				isVendorUnchecked: false,
				useSelValues: false,
				currConvRate: '1.00',
				currId: null,
				weightDec: 0,
				dimDec: 0,
				isFilterChanged: false,
				selectedTab: 'dashboard',
				prevSelValue: null,
				chartData: null,
				chartFilter: null,
				filterSearchValue: {
					Project: '',
					Vendor: '',
					'Purchase Order': '',
					'Group Structure': '',
					Structure: '',
					'Packing List': '',
					Mark: '',
					'Po Position': '',
					'Ident Code': '',
				},
				filterObj: {},
				status: {
					data: [],
					total: 0,
				},
				isClear: false,
				statusCompleted: true,
				// loadedProject: null,
				loadedPO: null,
			};
			return state;
		case actionTypes.DB_SET_FILTER_VALUES:
			return {
				...state,
				[action.keyName]: action.value,
			};
		case actionTypes.DB_SET_SELECTED_FILTER_VALUES:
			return {
				...state,
				selectedFilterValues: action.value,
			};
		case actionTypes.DB_SET_FILTER_CHANGE:
			return {
				...state,
				isFilterChanged: action.value,
			};
		case actionTypes.DB_SET_SYNC_STATUS:
			return {
				...state,
				statusCompleted: action.value,
			};
		case actionTypes.DB_SET_STATUS_DATA:
			return {
				...state,
				status: action.value,
			};
		default:
			return state;
	}
};

export default DBReducer;
