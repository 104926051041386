import React, { Component, Suspense } from 'react';
import { Switch, Route } from 'react-router-dom';
import Loadable from 'react-loadable';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table/dist/react-bootstrap-table.min.css';
import 'react-notifications-component/dist/theme.css';
import '../../node_modules/font-awesome/scss/font-awesome.scss';

import Loader from './layout/Loader';
import Aux from '../hoc/_Aux';
import ScrollToTop from './layout/ScrollToTop';
import ReactNotification from 'react-notifications-component';

import Login from './ProjectComponent/Login/LoginMain';
import ChangePassword from './ProjectComponent/Login/ChangePassword';

const AdminLayout = Loadable({
	loader: () => import('./layout/AdminLayout'),
	loading: Loader,
});

class App extends Component {
	constructor() {
		super();
		this.state = {
			login: 'needLogin' in sessionStorage ? JSON.parse(sessionStorage.getItem('needLogin')) : true,
			isAuthenticated:
				'isAuthenticated' in sessionStorage ? JSON.parse(sessionStorage.getItem('isAuthenticated')) : false,
		};
	}
	componentDidMount() {
		localStorage.clear();
		window.addEventListener('beforeunload', () => {
			sessionStorage.setItem('isShrink', false);
		});
	}
	aunthenticateLogin = () => {
		this.setState({
			login: JSON.parse(sessionStorage.getItem('needLogin')),
			isAuthenticated: JSON.parse(sessionStorage.getItem('isAuthenticated')),
		});
	};
	render() {
		const hashValue = window.location.hash.toLowerCase();
		const isChangePwd = hashValue.includes('changepassword') ? true : false;
		const { login, isAuthenticated } = this.state;
		return (
			<Aux>
				<ReactNotification />
				{isChangePwd ? (
					<Route path="/ChangePassword" component={ChangePassword} />
				) : (
					<React.Fragment>
						{login ? <Login aunthenticateLogin={this.aunthenticateLogin} /> : ''}
						{isAuthenticated ? (
							<ScrollToTop>
								<Suspense fallback={<Loader />}>
									<Switch>
										<Route path="/" component={AdminLayout} />
									</Switch>
								</Suspense>
							</ScrollToTop>
						) : (
							''
						)}
					</React.Fragment>
				)}
			</Aux>
		);
	}
}

export default App;
