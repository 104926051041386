import React, { Component } from 'react';
import '../../../assets/scss/style.scss';
import Aux from '../../../hoc/_Aux';
import { Row, Card, Col, Form, Button, InputGroup } from 'react-bootstrap';
import { get, post, put } from 'axios';
import routes from '../../../routes';
import { Route } from 'react-router-dom';
import config from '../../../../src/config';
import { isEmpty } from '../../utils/lodash';
import loginImg from '../../../assets/logo/Login.png';
import Swal from 'sweetalert2';
import { FaUserAlt, FaLock, FaEnvelopeOpenText } from 'react-icons/fa';
import { connect } from 'react-redux';
import * as actionTypes from '../../../store/actions';

class Login extends Component {
	constructor(props) {
		super(props);
		this.state = {
			form: {
				email: '',
				username: '',
				password: '',
			},
			showResetPwd: localStorage.getItem('fromChangePwd') ? localStorage.getItem('fromChangePwd') : false,
			showLogin: localStorage.getItem('fromChangePwd') ? false : true,
			resetEmail: '',
		};
	}
	componentDidMount() {
		if (localStorage.getItem('fromChangePwd')) localStorage.removeItem('fromChangePwd');
		document.addEventListener('keypress', this.handleKeyPress, false);
		document.addEventListener('contextmenu', (event) => event.preventDefault());
	}

	componentWillUnmount() {
		document.removeEventListener('keypress', this.handleKeyPress, false);
	}

	handleInputChange = (keyName, e) => {
		let value = e.target.value;
		this.setState({
			form: {
				...this.state.form,
				[keyName]: value,
			},
		});
	};

	handleKeyPress = (e) => {
		if (e.key === 'Enter') {
			this.proceedLogin();
		}
	};

	proceedLogin = () => {
		let { form } = this.state;
		var regEx = /\S+@\S+\.\S+/;
		let isValid = form.username.includes('@') ? regEx.test(form.username) : true;
		if (isEmpty(form.password) || isEmpty(form.username)) {
			Swal.fire({
				title: 'Enter the details to login',
				type: 'error',
			});
		} else if (!isValid)
			Swal.fire({
				title: 'Enter a valid email address',
				type: 'error',
			});
		else {
			let postData = { password: form.password };
			if (form.username.includes('@')) postData.email = form.username;
			else postData.username = form.username;
			sessionStorage.setItem('hostService', config.hostService);
			post(config.hostService + '/accounts/login/', postData)
				.then((response) => {
					let globalKey = response.data.key;
					window.$loginKey = globalKey;
					sessionStorage.setItem('loginKey', globalKey);
					sessionStorage.setItem('needLogin', false);
					sessionStorage.setItem('isAuthenticated', true);
					let apiHeaders = {
						headers: {
							Authorization: 'Token ' + sessionStorage.getItem('loginKey'),
						},
					};
					get(config.hostService + '/approval/all_approvals/', apiHeaders).then((res) => {
						sessionStorage.setItem('approvals', JSON.stringify(res.data.results));
					});
					get(config.hostService + '/accounts/menus/', apiHeaders).then((res) => {
						sessionStorage.setItem('menuModels', JSON.stringify(res.data.results));
					});
					get(config.hostService + '/get_permissions/', apiHeaders).then((response) => {
						sessionStorage.setItem('permissions', JSON.stringify(response.data.data));
						this.props.setDefDis(response.data.data.general.default_discipline);
						this.props.setPerm(response.data.data);
						//window.location.hash = '#/';
						this.props.aunthenticateLogin();
					});
				})
				.catch((err) => {
					Swal.fire({
						title: 'Login Failed',
						type: 'error',
					});
				});
		}
	};

	redirectToLogin = () => {
		this.setState({ showLogin: true, showResetPwd: false });
	};

	renderResetPassword = () => {
		this.setState({ showResetPwd: true, showLogin: false });
	};

	handleResetPassword = (e) => {
		this.setState({
			resetEmail: e.target.value,
		});
	};

	resetPassword = () => {
		if (!isEmpty(this.state.resetEmail)) {
			let data = {
				email: this.state.resetEmail,
			};
			post(config.hostService + '/accounts/password_reset/', data).then((res) => {
				Swal.fire({
					title: 'Reset link sent to the e-mail',
					type: 'success',
				});
				this.setState({ showLogin: true, showResetPwd: false });
			});
		} else
			Swal.fire({
				title: 'Enter the e-mail to reset password',
				type: 'error',
			});
	};

	render() {
		const { form } = this.state;
		const menu = routes.map((route, index) => {
			return route.component ? (
				<Route
					key={index}
					path={route.path}
					exact={route.exact}
					name={route.name}
					render={(props) => <route.component {...props} />}
				/>
			) : null;
		});
		const { showLogin, showResetPwd } = this.state;
		return (
			<Aux>
				<Row className="LoginPage">
					<Col md={12} style={{ position: 'relative' }}>
						<div
							style={{
								backgroundImage: `url(${loginImg})`,
								backgroundSize: 'contain',
								backgroundRepeat: 'no-repeat',
								backgroundPosition: 'center',
								backgroundColor: 'white',
								height: '100vh',
							}}
						/>
						<div style={{ position: 'absolute', top: '37%', left: '14%' }}>
							{showLogin ? (
								<React.Fragment>
									<Form>
										<Row style={{ paddingBottom: '10px' }}>
											<Col md={12} className="pad-zero">
												<FaUserAlt className="inputImg" />
												<Form.Control
													className="loginInput"
													required
													type="text"
													placeholder="Username/Email"
													name="username"
													value={form.username}
													onChange={(e) => this.handleInputChange('username', e)}
													onKeyPress={this.handleKeyPress}
												/>
											</Col>
										</Row>
										<Row style={{ paddingBottom: '10px' }}>
											<Col md={12} className="pad-zero">
												<FaLock className="inputImg" />
												<Form.Control
													className="loginInput"
													required
													type="password"
													placeholder="Password"
													name="password"
													value={form.password}
													onChange={(e) => this.handleInputChange('password', e)}
													onKeyPress={this.handleKeyPress}
												/>
											</Col>
										</Row>
										<Row>
											<Col md={12} className="alignCenter">
												<Button
													variant="primary"
													onClick={this.proceedLogin}
													style={{ padding: '0.3rem 1rem', borderRadius: '20px' }}
												>
													Sign In
												</Button>
											</Col>
										</Row>
									</Form>
									<Row>
										<Col md={12} className="alignCenter">
											<span className="text-muted">Forgot password ? &nbsp;</span>
											<span
												className="initReset hljs-link bold pointer"
												onClick={this.renderResetPassword}
											>
												Reset
											</span>
										</Col>
									</Row>
								</React.Fragment>
							) : (
								''
							)}
							{showResetPwd ? (
								<React.Fragment>
									<Form>
										<Row style={{ paddingBottom: '15px' }}>
											<Col md={12} className="pad-zero">
												<FaEnvelopeOpenText className="inputImg" />
												<Form.Control
													className="loginInput"
													type="email"
													placeholder="Email"
													name="email"
													onChange={this.handleResetPassword}
												/>
											</Col>
										</Row>
									</Form>
									<Row>
										<Col md={12} className="alignCenter">
											<button
												className="btn btn-primary mb-4 shadow-2"
												style={{ padding: '0.2rem 1rem', borderRadius: '20px' }}
												onClick={this.resetPassword}
											>
												Reset
											</button>
											<button
												className="btn btn-primary mb-4 shadow-2"
												style={{ padding: '0.2rem 1rem', borderRadius: '20px' }}
												onClick={this.redirectToLogin}
											>
												Sign In
											</button>
										</Col>
									</Row>
								</React.Fragment>
							) : (
								''
							)}
						</div>
					</Col>
				</Row>
			</Aux>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		defDiscipline: state.common.defDiscipline,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		setDefDis: (val) => dispatch({ type: actionTypes.GEN_SET_DEF_DIS, value: val }),
		setPerm: (val) => dispatch({ type: actionTypes.GEN_SET_PERM, value: val }),
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Login);
