import * as actionTypes from '../actions';
import config from '../../config';

const initialState = {
	service: config.hostService,
	getPerm: JSON.parse(sessionStorage.getItem('permissions')),
	isMenuExpand: false,
	defDiscipline: 'Structure',
	disList: null,
	searchFilter: {
		searchText: [],
		filter: [],
		filterValues: [],
		active: false,
		sort: '',
		sortParams: {
			text: [],
			id: [],
			order: 'asc',
		},
	},
	search: '',
	filter: '',
	sort: '',
	searchURL: null,
	filterParams: null,
	sortParams: {
		text: [],
		id: [],
		order: 'asc',
	},
};

const common = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.GEN_SEARCH:
			return {
				...state,
				search: action.value,
			};
		case actionTypes.GEN_FILTER:
			return {
				...state,
				filter: action.value,
			};
		case actionTypes.GEN_SEARCH_URL:
			return {
				...state,
				searchURL: action.value,
			};
		case actionTypes.GEN_SORT:
			return {
				...state,
				sort: action.value,
			};
		case actionTypes.GEN_SORT_PARAMS:
			return {
				...state,
				searchFilter: {
					...state.searchFilter,
					sortParams: action.value,
				},
			};
		case actionTypes.GEN_FILTER_PARAMS:
			return {
				...state,
				filterParams: action.value,
			};
		case actionTypes.GEN_IS_MENU_EXPAND:
			Promise.resolve({
				type: 'GEN_IS_MENU_EXPAND',
				action,
			});
			return {
				...state,
				isMenuExpand: action.value,
			};
		case actionTypes.GEN_SET_DEF_DIS:
			return {
				...state,
				defDiscipline: action.value,
			};
		case actionTypes.GEN_SET_PERM:
			return {
				...state,
				getPerm: action.value,
			};
		case actionTypes.GEN_SET_DISLIST:
			return {
				...state,
				disList: action.value,
			};
		case actionTypes.GEN_CLEARSEARCHFILTER:
			return {
				...state,
				searchURL: null,
				searchFilter: {
					searchText: [],
					filter: [],
					filterValues: [],
					active: false,
					sort: '',
					sortParams: {
						text: [],
						id: [],
						order: 'asc',
					},
				},
			};
		case actionTypes.GEN_SET_SEARCHFILTER:
			return {
				...state,
				searchFilter: action.value,
			};

		default:
			return state;
	}
};

export default common;
