import * as actionTypes from '../actions';

const initialState = {
	projSettingsId: null,
	isPO: false,
};

const plReducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.SYS_SET_PROJSETT_ID:
			return {
				...state,
				projSettingsId: action.id,
			};
		case actionTypes.SYS_SET_IS_PO:
			return {
				...state,
				isPO: action.value,
			};
		case actionTypes.SYS_SET_TO_INITIALSTATE:
			return {
				initialState,
			};
		default:
			return state;
	}
};

export default plReducer;
