import React, { Component } from 'react';
import Aux from '../../../hoc/_Aux';
import PropTypes from 'prop-types';
import { Row, Card, Col, Form, Button, InputGroup } from 'react-bootstrap';
import { store } from 'react-notifications-component';
import { isEmpty } from '../../utils/lodash';
import { post } from 'axios';
import config from '../../../../src/config';
import loginImg from '../../../assets/logo/Login.png';
import { FaUnlock } from 'react-icons/fa';
import { GoShield } from 'react-icons/go';
import Swal from 'sweetalert2';

class ChangePassword extends Component {
	static contextTypes = {
		router: PropTypes.object,
	};
	constructor(props) {
		super(props);
		this.state = {
			form: {
				newPwd: '',
				reTypePwd: '',
			},
			token: '',
			notification: {
				message: 'Enter the all fields to proceed',
				type: 'danger',
				insert: 'top',
				container: 'top-center',
				animationIn: ['animated', 'fadeIn'],
				animationOut: ['animated', 'fadeOut'],
				dismiss: {
					duration: 2500,
				},
			},
		};
	}

	componentDidMount() {
		let url = window.location.hash;
		let tokenValue = [];
		if (url.includes('token')) {
			tokenValue = url.split('token=');
			post(config.hostService + '/accounts/password_reset/validate_token/', {
				token: tokenValue[tokenValue.length - 1],
			})
				.then((res) => {
					this.setState({ token: tokenValue[tokenValue.length - 1] });
				})
				.catch((err) => {
					localStorage.setItem('fromChangePwd', true);
					this.redirectToLogin();
					Swal.fire({
						title: 'Token expired. Kindly try again',
						type: 'error',
					});
				});
		}
	}

	handleInputChange = (keyName, e) => {
		let value = e.target.value;
		this.setState({
			form: {
				...this.state.form,
				[keyName]: value,
			},
		});
	};

	handleKeyPress = (e) => {
		if (e.key === 'Enter') {
			this.proceedToChange();
		}
	};

	redirectToLogin = () => {
		window.location.href = '';
	};

	proceedToChange = () => {
		const { newPwd, reTypePwd } = this.state.form;
		if (!isEmpty(newPwd) && !isEmpty(reTypePwd) && newPwd === reTypePwd) {
			let data = {
				password: newPwd,
				token: this.state.token,
			};
			post(config.hostService + '/accounts/password_reset/confirm/', data)
				.then((res) => {
					Swal.fire({
						title: 'Password changed successfully',
						type: 'success',
					});
					this.redirectToLogin();
				})
				.catch((err) => {
					Swal.fire({
						title: 'Password changing failed',
						type: 'error',
					});
					this.redirectToLogin();
				});
		} else if (newPwd !== reTypePwd)
			Swal.fire({
				title: 'Passwords doesnot match',
				type: 'error',
			});
		else
			Swal.fire({
				title: 'Enter the details to proceed',
				type: 'error',
			});
	};

	render() {
		const { form } = this.state;
		return (
			<Aux>
				<Row className="LoginPage">
					<Col md={12} style={{ position: 'relative' }}>
						<div
							style={{
								backgroundImage: `url(${loginImg})`,
								backgroundSize: 'contain',
								backgroundRepeat: 'no-repeat',
								backgroundPosition: 'center',
								backgroundColor: 'white',
								height: '100vh',
							}}
						/>
						<div style={{ position: 'absolute', top: '37%', left: '14%' }}>
							<Form>
								<Row style={{ paddingBottom: '15px' }}>
									<Col md={12} className="pad-zero">
										<FaUnlock className="inputImg" />
										<Form.Control
											className="loginInput"
											type="password"
											placeholder="New password"
											name="new_password"
											value={form.newPwd}
											onChange={(e) => this.handleInputChange('newPwd', e)}
											onKeyPress={this.handleKeyPress}
										/>
									</Col>
								</Row>
								<Row style={{ paddingBottom: '15px' }}>
									<Col md={12} className="pad-zero">
										<GoShield className="inputImg" />
										<Form.Control
											className="loginInput"
											type="password"
											placeholder="Re-enter the new password"
											name="retype_password"
											value={form.reTypePwd}
											onChange={(e) => this.handleInputChange('reTypePwd', e)}
											onKeyPress={this.handleKeyPress}
										/>
									</Col>
								</Row>
							</Form>
							<Row>
								<Col md={12} className="alignCenter">
									<button
										className="btn btn-primary mb-4 shadow-2"
										style={{ padding: '0.2rem 1rem', borderRadius: '20px' }}
										onClick={this.proceedToChange}
									>
										Change Password
									</button>
									<button
										className="btn btn-primary mb-4 shadow-2"
										style={{ padding: '0.2rem 1rem', borderRadius: '20px' }}
										onClick={this.redirectToLogin}
									>
										Cancel
									</button>
								</Col>
							</Row>
						</div>
					</Col>
				</Row>
			</Aux>
		);
	}
}

export default ChangePassword;
