import * as actionTypes from '../actions';

const initialState = {
	plMain: {},
	pl_create: {
		renderData: {},
		initData: null,
		kindOfPkgList: [],
		readOnly: false,
		isNewRevision: false,
		showForm: false,
		isStrModified: false,
		isPLFormValid: false,
		isCountryInvalid: false,
		isStructureInValid: false,
		isPoInvalid: false,
		loading: false,
		isOverlay: false,
		detailsFormValid: false,
		disStructure: false,
		isListModified: true,
	},
	pkgForm: {
		vendor_package: '',
		truck_number: '',
	},
	marksList: null,
	addedList: null,
	projSettingsData: [],
	poUOM: {},
	plInputForm : null
};

const plReducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.PLMAIN_SELID:
			return {
				...state,
				plMain: { ...state.plMain, id: action.id, editMode: action.mode, readOnly: action.readOnly },
			};
		case actionTypes.PL_CREATE:
			return {
				...state,
				pl_create: action.value,
			};
		case actionTypes.PL_CREATE_FORM:
			return {
				...state,
				pl_create: { ...state.pl_create, form: action.value },
			};
		case actionTypes.PL_CREATE_DATA:
			return {
				...state,
				pl_create: { ...state.pl_create, renderData: action.value },
			};
		case actionTypes.PL_SET_TO_INITIALSTATE:
			let val = {
				plMain: {},
				pl_create: {
					//form: {},
					renderData: {},
					initData: null,
					kindOfPkgList: [],
					readOnly: false,
					isNewRevision: false,
					showForm: false,
					isStrModified: false,
					loading: false,
					isOverlay: false,
					detailsFormValid: false,
					disStructure: false,
					isListModified: true,
				},
				pkgForm: {
					vendor_package: '',
					truck_number: '',
				},
				marksList: null,
				addedList: null,
				projSettingsData: [],
				poUOM: {},
				plInputForm : null
			};
			return val;
		case actionTypes.PL_PKGFORM:
			return {
				...state,
				pkgForm: action.value,
			};
		case actionTypes.PL_SET_MARKS:
			return {
				...state,
				marksList: action.value,
			};
		case actionTypes.PL_SET_ADDED_LIST:
			return {
				...state,
				addedList: action.value,
			};
		case actionTypes.PL_SET_PROJSETTINGS:
			return {
				...state,
				projSettingsData: action.value,
			};
		case actionTypes.PL_SET_PO_UOM:
			return {
				...state,
				poUOM: action.value,
			};
		case actionTypes.PL_SET_FORM:
			return {
				...state,
				plInputForm: action.value,
			};
		default:
			return state;
	}
};

export default plReducer;
