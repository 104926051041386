import { combineReducers } from 'redux';
import genReducer from './general';
import sdReducer from './shopDrawingReducer';
import plReducer from './PLInputReducer';
import DBReducer from './DashboardReducer';
import SysReducer from './SystemSettingsReducer';
import common from './CommonReducer';

export default combineReducers({
	genReducer,
	sdReducer,
	plReducer,
	DBReducer,
	SysReducer,
	common,
});
