export const COLLAPSE_MENU = 'COLLAPSE_MENU';
export const COLLAPSE_TOGGLE = 'COLLAPSE_TOGGLE';
export const FULL_SCREEN = 'FULL_SCREEN';
export const FULL_SCREEN_EXIT = 'FULL_SCREEN_EXIT';
export const CHANGE_LAYOUT = 'CHANGE_LAYOUT';
export const LAYOUT_TYPE = 'LAYOUT_TYPE';
export const RESET = 'RESET';
export const NAV_BACK_COLOR = 'NAV_BACK_COLOR';
export const NAV_BACK_IMAGE = 'NAV_BACK_IMAGE';
export const NAV_BRAND_COLOR = 'NAV_BRAND_COLOR';
export const HEADER_BACK_COLOR = 'HEADER_BACK_COLOR';
export const NAV_ICON_COLOR = 'NAV_ICON_COLOR';
export const LAYOUT6_BACKGROUND = 'LAYOUT6_BACKGROUND';
export const RTL_LAYOUT = 'RTL_LAYOUT';
export const NAV_FIXED_LAYOUT = 'NAV_FIXED_LAYOUT';
export const HEADER_FIXED_LAYOUT = 'HEADER_FIXED_LAYOUT';
export const BOX_LAYOUT = 'BOX_LAYOUT';
export const NAV_DROPDOWN_ICON = 'NAV_DROPDOWN_ICON';
export const NAV_LIST_ICON = 'NAV_LIST_ICON';
export const NAV_ACTIVE_LIST_COLOR = 'NAV_ACTIVE_LIST_COLOR';
export const NAV_LIST_TITLE_COLOR = 'NAV_LIST_TITLE_COLOR';
export const NAV_LIST_TITLE_HIDE = 'NAV_LIST_TITLE_HIDE';
export const NAV_CONTENT_LEAVE = 'NAV_CONTENT_LEAVE';
export const NAV_COLLAPSE_LEAVE = 'NAV_COLLAPSE_LEAVE';
export const CONFIG_BLOCK = 'CONFIG_BLOCK';
export const CHANGE_PRE_LAYOUT = 'CHANGE_PRE_LAYOUT';
export const MASTER_POTYPE_SELID = 'MASTER_POTYPE_SELID';
export const SDMAIN_SELID = 'SDMAIN_SELID';
export const SD_CREATE = 'SD_CREATE';
export const SD_DRAFT = 'SD_DRAFT';
export const SD_CREATEFORM = 'SD_CREATEFORM';
export const SD_SET_RESPONSE_DATA = 'SD_SET_RESPONSE_DATA';
export const SD_SET_BC = 'SD_SET_BC';
export const SD_SET_REV_BC = 'SD_SET_REV_BC';
export const SD_SET_ISREVISION = 'SD_SET_ISREVISION';
export const SD_SET_TO_INITIALSTATE = 'SD_SET_TO_INITIALSTATE';
export const SD_SET_FORM = 'SD_SET_FORM';
export const PLMAIN_SELID = 'PLMAIN_SELID';
export const PL_CREATE = 'PL_CREATE';
export const PL_CREATE_FORM = 'PL_CREATE_FORM';
export const PL_CREATE_DATA = 'PL_CREATE_DATA';
export const PL_SET_TO_INITIALSTATE = 'PL_SET_TO_INITIALSTATE';
export const PL_PKGFORM = 'PL_PKGFORM';
export const PL_SET_MARKS = 'PL_SET_MARKS';
export const PL_SET_ADDED_LIST = 'PL_SET_ADDED_LIST';
export const PL_SET_PROJSETTINGS = 'PL_SET_PROJSETTINGS';
export const PL_SET_PO_UOM = 'PL_SET_PO_UOM';
export const PL_SET_FORM = 'PL_SET_FORM';
export const DB_SET_FILTER_VALUES = 'DB_SET_FILTER_VALUES';
export const DB_SET_TO_INITIALSTATE = 'DB_SET_TO_INITIALSTATE';
export const DB_SET_SELECTED_FILTER_VALUES = 'DB_SET_SELECTED_FILTER_VALUES';
export const DB_SET_FILTER_CHANGE = 'DB_SET_FILTER_CHANGE';
export const DB_SET_SYNC_STATUS = 'DB_SET_SYNC_STATUS';
export const DB_SET_STATUS_DATA = 'DB_SET_STATUS_DATA';
export const SYS_SET_PROJSETT_ID = 'SYS_SET_PROJSETT_ID';
export const SYS_SET_IS_PO = 'SYS_SET_IS_PO';
export const SYS_SET_TO_INITIALSTATE = 'SYS_SET_TO_INITIALSTATE';
export const GEN_SEARCH = 'GEN_SEARCH';
export const GEN_FILTER = 'GEN_FILTER';
export const GEN_SORT = 'GEN_SORT';
export const GEN_SORT_PARAMS = 'GEN_SORT_PARAMS';
export const GEN_FILTER_PARAMS = 'GEN_FILTER_PARAMS';
export const GEN_SEARCH_URL = 'GEN_SEARCH_URL';
export const GEN_CLEARSEARCHFILTER = 'GEN_CLEARSEARCHFILTER';
export const GEN_INIT_LOGIN = 'GEN_INIT_LOGIN';
export const GEN_IS_MENU_EXPAND = 'GEN_IS_MENU_EXPAND';
export const GEN_SET_SEARCHFILTER = 'GEN_SET_SEARCHFILTER';
export const GEN_SET_DEF_DIS = 'GEN_SET_DEF_DIS';
export const GEN_SET_DISLIST = 'GEN_SET_DISLIST';
export const GEN_SET_PERM = 'GEN_SET_PERM';
