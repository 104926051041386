import React from "react";
import $ from "jquery";

window.jQuery = $;
window.$ = $;
global.jQuery = $;

const Notifications = React.lazy(() =>
  import("./App/ProjectComponent/Notifications")
);
const DashboardDefault = React.lazy(() =>
  import("./App/ProjectComponent/Dashboard/views/Dashboard")
);
const DefaultPage = React.lazy(() =>
  import("./App/ProjectComponent/DefaultPage")
);

const ShopDrawingDefault = React.lazy(() =>
  import("./App/ProjectComponent/ShopDrawing/ShopDrawingMain")
);
const ShopDrawingCreate = React.lazy(() =>
  import("./App/ProjectComponent/ShopDrawing/CreateDrawing")
);
const ShopDrawingView = React.lazy(() =>
  import("./App/ProjectComponent/ShopDrawing/ViewDrawings")
);

const LatestMBL = React.lazy(() =>
  import("./App/ProjectComponent/ShopDrawing/LatestMBL")
);
const LatestMBLCreate = React.lazy(() =>
  import("./App/ProjectComponent/ShopDrawing/LatestMBL/CreateMBL")
);
const LatestMBLView = React.lazy(() =>
  import("./App/ProjectComponent/ShopDrawing/LatestMBL/ViewLatestMBL")
);

const PLInputsDefault = React.lazy(() =>
  import("./App/ProjectComponent/PLInputs/PLInputMain")
);
const PLInputsCreate = React.lazy(() =>
  import("./App/ProjectComponent/PLInputs/CreatePLInputForm")
);
const PLInputsCreatePackage = React.lazy(() =>
  import("./App/ProjectComponent/PLInputs/CreatePackage")
);
const PLInputsView = React.lazy(() =>
  import("./App/ProjectComponent/PLInputs/ViewPLInputDetails")
);

const POTypeDefault = React.lazy(() =>
  import("./App/ProjectComponent/Masters/POType/POTypeMain")
);

const POTypeCreate = React.lazy(() =>
  import("./App/ProjectComponent/Masters/POType/POTypeForm")
);

const DepartmentDefault = React.lazy(() =>
  import("./App/ProjectComponent/Masters/Department/DepartmentMain")
);

const DepartmentCreate = React.lazy(() =>
  import("./App/ProjectComponent/Masters/Department/DepartmentForm")
);

const CountryDefault = React.lazy(() =>
  import("./App/ProjectComponent/Masters/Country/CountryMain")
);

const CountryCreate = React.lazy(() =>
  import("./App/ProjectComponent/Masters/Country/CountryForm")
);

const CityDefault = React.lazy(() =>
  import("./App/ProjectComponent/Masters/City/CityMain")
);

const CityCreate = React.lazy(() =>
  import("./App/ProjectComponent/Masters/City/CityForm")
);

const RegionDefault = React.lazy(() =>
  import("./App/ProjectComponent/Masters/Region/RegionMain")
);

const RegionCreate = React.lazy(() =>
  import("./App/ProjectComponent/Masters/Region/RegionForm")
);

const PackageDefault = React.lazy(() =>
  import("./App/ProjectComponent/Masters/Package/PackageMain")
);

const PackageCreate = React.lazy(() =>
  import("./App/ProjectComponent/Masters/Package/PackageForm")
);
const PackagePO = React.lazy(() =>
  import("./App/ProjectComponent/Masters/Package/POList")
);

const EmpDefault = React.lazy(() =>
  import("./App/ProjectComponent/Masters/Employee/EmployeeMain")
);

const EmpCreate = React.lazy(() =>
  import("./App/ProjectComponent/Masters/Employee/EmployeeForm")
);

const UOMDefault = React.lazy(() =>
  import("./App/ProjectComponent/Masters/UOM/UOMMain")
);

const UOMCreate = React.lazy(() =>
  import("./App/ProjectComponent/Masters/UOM/UOMForm")
);

const CompanyDefault = React.lazy(() =>
  import("./App/ProjectComponent/Masters/Company/CompanyMain")
);

const CompanyCreate = React.lazy(() =>
  import("./App/ProjectComponent/Masters/Company/CompanyForm")
);

const AddressDefault = React.lazy(() =>
  import("./App/ProjectComponent/Masters/Address/AddressMain")
);

const AddressCreate = React.lazy(() =>
  import("./App/ProjectComponent/Masters/Address/AddressForm")
);

const VendorDefault = React.lazy(() =>
  import("./App/ProjectComponent/Masters/Vendor/VendorMain")
);

const VendorCreate = React.lazy(() =>
  import("./App/ProjectComponent/Masters/Vendor/VendorForm")
);

const ProjectDefault = React.lazy(() =>
  import("./App/ProjectComponent/Project/ProjectMain")
);

const ProjectCreate = React.lazy(() =>
  import("./App/ProjectComponent/Project/ProjectForm")
);

const SequenceDefault = React.lazy(() =>
  import("./App/ProjectComponent/SystemSettings/Sequence/SequenceMain")
);
const SequenceCreate = React.lazy(() =>
  import("./App/ProjectComponent/SystemSettings/Sequence/SequenceForm")
);

const EditSequence = React.lazy(() =>
  import("./App/ProjectComponent/SystemSettings/Sequence/EditSequence")
);
const EditSequenceForm = React.lazy(() =>
  import("./App/ProjectComponent/SystemSettings/Sequence/EditSequenceForm")
);

const QCType = React.lazy(() =>
  import("./App/ProjectComponent/SystemSettings/QCSettings/QCType")
);
const QCTypeCreate = React.lazy(() =>
  import("./App/ProjectComponent/SystemSettings/QCSettings/QCType/QCTypeForm")
);

const QCMethod = React.lazy(() =>
  import("./App/ProjectComponent/SystemSettings/QCSettings/QCMethod")
);
const QCMethodCreate = React.lazy(() =>
  import("./App/ProjectComponent/SystemSettings/QCSettings/QCMethod/QCMethodForm")
);

const QCPainting = React.lazy(() =>
  import("./App/ProjectComponent/SystemSettings/QCSettings/QCPainting")
);
const QCPaintingCreate = React.lazy(() =>
  import("./App/ProjectComponent/SystemSettings/QCSettings/QCPainting/QCPaintingForm")
);

const ProjectSettingsDefault = React.lazy(() =>
  import("./App/ProjectComponent/SystemSettings/ProjectSettings/ProjectSettingsMain")
);

const DimensionRules = React.lazy(() =>
  import("./App/ProjectComponent/SystemSettings/ProjectSettings/ProjectSettingsForm/Dimension")
);
const ExtraPieceRules = React.lazy(() =>
  import("./App/ProjectComponent/SystemSettings/ProjectSettings/ProjectSettingsForm/Extrapiece")
);
const PipingExtraPieceRules = React.lazy(() =>
  import("./App/ProjectComponent/SystemSettings/ProjectSettings/ProjectSettingsForm/PipingExtraPiece")
);
const PLTemplateRules = React.lazy(() =>
  import("./App/ProjectComponent/SystemSettings/ProjectSettings/ProjectSettingsForm/PLTemplate")
);
const PLGenerationRules = React.lazy(() =>
  import("./App/ProjectComponent/SystemSettings/ProjectSettings/ProjectSettingsForm/PLGeneration")
);
const HybridRules = React.lazy(() =>
  import("./App/ProjectComponent/SystemSettings/ProjectSettings/ProjectSettingsForm/Hybrid")
);
const CurrencyRules = React.lazy(() =>
  import("./App/ProjectComponent/SystemSettings/ProjectSettings/ProjectSettingsForm/Currency")
);

const NameOfGoods = React.lazy(() =>
  import("./App/ProjectComponent/SystemSettings/ProjectSettings/NameOfGoods")
);
const NameOfGoodsForm = React.lazy(() =>
  import("./App/ProjectComponent/SystemSettings/ProjectSettings/NameOfGoods/CreateForm")
);

const PurchaseDefault = React.lazy(() =>
  import("./App/ProjectComponent/Purchase/PurchaseMain")
);
const PurchaseCreate = React.lazy(() =>
  import("./App/ProjectComponent/Purchase/PurchaseForm")
);

const UpdatePODefault = React.lazy(() =>
  import("./App/ProjectComponent/UpdateMarianDetails")
);
const UpdatePOCreate = React.lazy(() =>
  import("./App/ProjectComponent/UpdateMarianDetails/UpdateMarianForm")
);

const ProgressReport = React.lazy(() =>
  import("./App/ProjectComponent/ProgressReport")
);
const ProgressReportCreate = React.lazy(() =>
  import("./App/ProjectComponent/ProgressReport/ProgressReportForm")
);

const PackingListDefault = React.lazy(() =>
  import("./App/ProjectComponent/PackingList/PackingListMain")
);
const PackingListView = React.lazy(() =>
  import("./App/ProjectComponent/PackingList/ViewPackingList")
);
const PackingListEdit = React.lazy(() =>
  import("./App/ProjectComponent/PackingList/PackingListDetails")
);

const DMSDefault = React.lazy(() => import("./App/ProjectComponent/DMS/index"));

const UserDefault = React.lazy(() =>
  import("./App/ProjectComponent/Accounts/User/UserMain")
);
const UserCreate = React.lazy(() =>
  import("./App/ProjectComponent/Accounts/User/UserCreateForm")
);

const UserGroupDefault = React.lazy(() =>
  import("./App/ProjectComponent/Accounts/UserGroup/UserGroupMain")
);
const UserGroupCreate = React.lazy(() =>
  import("./App/ProjectComponent/Accounts/UserGroup/UserGroupCreateForm")
);

const UserProfile = React.lazy(() =>
  import("./App/ProjectComponent/UserPrefrences/Profile")
);

const MailConfigurationsDefault = React.lazy(() =>
  import("./App/ProjectComponent/SystemSettings/MailConfigurations/MailConfigurationsMain")
);
const MailConfigurationsCreate = React.lazy(() =>
  import("./App/ProjectComponent/SystemSettings/MailConfigurations/MailConfigurationsForm")
);

const OnsiteStatus = React.lazy(() =>
  import("./App/ProjectComponent/Packages/OnsiteStatus/OnsiteStatusMain")
);
const ShippedStatus = React.lazy(() =>
  import("./App/ProjectComponent/Packages/ShippedStatus/ShippedStatusMain")
);
const AllPackagesEdit = React.lazy(() =>
  import("./App/ProjectComponent/Packages/PackageEditForm")
);

const ChangePassword = React.lazy(() =>
  import("./App/ProjectComponent/Login/ChangePassword")
);
const Login = React.lazy(() =>
  import("./App/ProjectComponent/Login/LoginMain")
);

const ReportsDefault = React.lazy(() =>
  import("./App/ProjectComponent/Reports")
);
const PLReport = React.lazy(() =>
  import("./App/ProjectComponent/Reports/PackingListReport/PLReportMain")
);
const MarkCummReport = React.lazy(() =>
  import("./App/ProjectComponent/Reports/MarkwiseCumulativeReport/MarkwiseCumulative")
);
const MarkwiseReport = React.lazy(() =>
  import("./App/ProjectComponent/Reports/MarkwiseReport/MarkwiseReport")
);
const PackageReport = React.lazy(() =>
  import("./App/ProjectComponent/Reports/PackagewiseReport/PackageReportMain")
);

const StructureReport = React.lazy(() =>
  import("./App/ProjectComponent/Reports/StructurewiseReport/StructureReport")
);
const GroupStructureReport = React.lazy(() =>
  import("./App/ProjectComponent/Reports/GroupStructurewise/GroupStructureReport")
);
const MWPErectionReport = React.lazy(() =>
  import("./App/ProjectComponent/Reports/MWPErectionReport")
);
const DetailFigureReport = React.lazy(() =>
  import("./App/ProjectComponent/Reports/DetailFigure/DetailFigureReport")
);
const PurchaseOrderwiseReport = React.lazy(() =>
  import("./App/ProjectComponent/Reports/POwiseReport/POwiseReport")
);
const IdentCodewiseReport = React.lazy(() =>
  import("./App/ProjectComponent/Reports/IdentCodeReport/IdentCodewiseReport")
);
const SummaryReport = React.lazy(() =>
  import("./App/ProjectComponent/Reports/SummaryReport/SummaryReport")
);
const ForecastReport = React.lazy(() =>
  import("./App/ProjectComponent/Reports/ForecastReport/ForecastReport")
);
const MarianReport = React.lazy(() =>
  import("./App/ProjectComponent/Reports/MarianReport")
);
const MarianReleaseReport = React.lazy(() =>
  import("./App/ProjectComponent/Reports/MarianReleaseReport")
);
// const LDSummaryReport = React.lazy(() =>
//   import("./App/ProjectComponent/Reports/LD/LDSummaryReport")
// );
// const LDDetailedReport = React.lazy(() =>
//   import("./App/ProjectComponent/Reports/LD/LDDetailedReport")
// );

const FastenersReport = React.lazy(() =>
  import("./App/ProjectComponent/Reports/Fasteners")
);

const TraceabilityReport = React.lazy(() =>
  import("./App/ProjectComponent/Reports/Piping/TraceabilityReport")
);
const PipingPackingListReport = React.lazy(() =>
  import("./App/ProjectComponent/Reports/Piping/PackingListReport")
);
const SummaryPOReport = React.lazy(() =>
  import("./App/ProjectComponent/Reports/Piping/SummaryPOReport")
);
const PipingPOPositionwiseReport = React.lazy(() =>
  import("./App/ProjectComponent/Reports/Piping/POWiseReport")
);
const PipingMTCwiseReport = React.lazy(() =>
  import("./App/ProjectComponent/Reports/Piping/MTCWiseReport")
);
const PipingMTCDetailsReport = React.lazy(() =>
  import("./App/ProjectComponent/Reports/Piping/MTCDetailsReport")
);

const CurrencyDefault = React.lazy(() =>
  import("./App/ProjectComponent/Masters/Currency/CurrencyMain")
);
const CurrencyCreate = React.lazy(() =>
  import("./App/ProjectComponent/Masters/Currency/CurrencyForm")
);

const CurrencyRateDefault = React.lazy(() =>
  import("./App/ProjectComponent/Masters/CurrencyRate")
);
const CurrencyRateCreate = React.lazy(() =>
  import("./App/ProjectComponent/Masters/CurrencyRate/CurrencyRateForm")
);

const LevelDefault = React.lazy(() =>
  import("./App/ProjectComponent/Masters/Levels/LevelMain")
);
const LevelCreate = React.lazy(() =>
  import("./App/ProjectComponent/Masters/Levels/LevelForm")
);

const DesignationDefault = React.lazy(() =>
  import("./App/ProjectComponent/Masters/Designation/DesignationMain")
);
const DesignationCreate = React.lazy(() =>
  import("./App/ProjectComponent/Masters/Designation/DesignationForm")
);

const EmploymentTypeDefault = React.lazy(() =>
  import("./App/ProjectComponent/Masters/EmploymentType/EmploymentTypeMain")
);
const EmploymentTypeCreate = React.lazy(() =>
  import("./App/ProjectComponent/Masters/EmploymentType/EmploymentTypeForm")
);

const IdentDefault = React.lazy(() =>
  import("./App/ProjectComponent/Masters/Ident/IdentMain")
);
const IdentCreate = React.lazy(() =>
  import("./App/ProjectComponent/Masters/Ident/IdentForm")
);

const POPosDefault = React.lazy(() =>
  import("./App/ProjectComponent/SystemSettings/ProjectSettings/POPosition")
);
const POPosCreate = React.lazy(() =>
  import("./App/ProjectComponent/SystemSettings/ProjectSettings/POPosition/CreatePOPosForm")
);

const SysSettingsDefault = React.lazy(() =>
  import("./App/ProjectComponent/SystemSettings")
);

const ApprovalRules = React.lazy(() =>
  import("./App/ProjectComponent/SystemSettings/ProjectSettings/ApprovalRules")
);
const ApprovalRulesCreate = React.lazy(() =>
  import("./App/ProjectComponent/SystemSettings/ProjectSettings/ApprovalRules/ApprovalForm")
);

const Reasons = React.lazy(() =>
  import("./App/ProjectComponent/SystemSettings/ProjectSettings/Reasons/ReasonsMain")
);
const ReasonsCreate = React.lazy(() =>
  import("./App/ProjectComponent/SystemSettings/ProjectSettings/Reasons/ReasonsForm")
);

const MastersDefault = React.lazy(() =>
  import("./App/ProjectComponent/Masters/index")
);

const CompareShopDrawings = React.lazy(() =>
  import("./App/ProjectComponent/ShopDrawing/CompareSD")
);

const HybridMBL = React.lazy(() =>
  import("./App/ProjectComponent/ShopDrawing/HybridMBL")
);

const MBLSummary = React.lazy(() =>
  import("./App/ProjectComponent/ShopDrawing/MBLSummaryReport")
);

const Forecasting = React.lazy(() =>
  import("./App/ProjectComponent/Forecasting")
);
const ForecastingCreate = React.lazy(() =>
  import("./App/ProjectComponent/Forecasting/CreateForecasting")
);

const IRC = React.lazy(() => import("./App/ProjectComponent/IRC"));
const IRCCreate = React.lazy(() =>
  import("./App/ProjectComponent/IRC/IRCForm")
);

const NotifySettingsDefault = React.lazy(() =>
  import("./App/ProjectComponent/SystemSettings/Notifications/Settings")
);
const NotifySettingsCreate = React.lazy(() =>
  import("./App/ProjectComponent/SystemSettings/Notifications/Settings/CreateSettings")
);

const NotifyFollowersDefault = React.lazy(() =>
  import("./App/ProjectComponent/SystemSettings/Notifications/Followers")
);
const NotifyFollowersCreate = React.lazy(() =>
  import("./App/ProjectComponent/SystemSettings/Notifications/Followers/CreateFollowers")
);

const Marks = React.lazy(() =>
  import("./App/ProjectComponent/SystemSettings/ProjectSettings/Marks")
);
const MarksCreate = React.lazy(() =>
  import("./App/ProjectComponent/SystemSettings/ProjectSettings/Marks/CreateForm")
);

const Structures = React.lazy(() =>
  import("./App/ProjectComponent/SystemSettings/ProjectSettings/Structures")
);
const StructuresCreate = React.lazy(() =>
  import("./App/ProjectComponent/SystemSettings/ProjectSettings/Structures/CreateForm")
);

const PrismDefault = React.lazy(() => import("./App/ProjectComponent/Prism"));

const routes = [
  {
    path: "/ChangePassword",
    exact: true,
    name: "Default",
    component: ChangePassword,
  },
  {
    path: "/Login",
    exact: true,
    name: "Default",
    component: Login,
  },
  {
    path: "/notifications",
    exact: true,
    name: "Default",
    component: Notifications,
  },
  {
    path: "/dashboard",
    exact: true,
    name: "Dashboard",
    component: DashboardDefault,
  },
  {
    path: "/dashboard/Structure",
    exact: true,
    name: "Dashboard",
    component: DashboardDefault,
  },
  {
    path: "/dashboard/Piping",
    exact: true,
    name: "Dashboard",
    component: DashboardDefault,
  },
  {
    path: "/defaultPage",
    exact: true,
    name: "Default",
    component: DefaultPage,
  },
  {
    path: "/defaultEmpty",
    exact: true,
    name: "Default",
    component: DefaultPage,
  },
  {
    path: "/ShopDrawing/default",
    exact: true,
    name: "Default",
    component: ShopDrawingDefault,
  },
  {
    path: "/ShopDrawing/default/Form",
    exact: true,
    name: "Default",
    component: ShopDrawingCreate,
  },
  {
    path: "/ShopDrawing/default/view",
    exact: true,
    name: "Default",
    component: ShopDrawingView,
  },
  {
    path: "/ShopDrawing/default/revision",
    exact: true,
    name: "Default",
    component: ShopDrawingDefault,
  },
  {
    path: "/ShopDrawing/default/Form/revision",
    exact: true,
    name: "Default",
    component: ShopDrawingCreate,
  },
  {
    path: "/Packages/default",
    exact: true,
    name: "Default",
    component: PLInputsDefault,
  },
  {
    path: "/Packages/default/Form",
    exact: true,
    name: "Default",
    component: PLInputsCreate,
  },
  {
    path: "/Packages/default/packageList",
    exact: true,
    name: "Default",
    component: PLInputsCreatePackage,
  },
  {
    path: "/Packages/default/view",
    exact: true,
    name: "Default",
    component: PLInputsView,
  },
  {
    path: "/Masters/POType",
    exact: true,
    name: "Default",
    component: POTypeDefault,
  },
  {
    path: "/Masters/POType/Form",
    exact: true,
    name: "Default",
    component: POTypeCreate,
  },
  {
    path: "/Masters/Department",
    exact: true,
    name: "Default",
    component: DepartmentDefault,
  },
  {
    path: "/Masters/Department/Form",
    exact: true,
    name: "Default",
    component: DepartmentCreate,
  },
  {
    path: "/Masters/Country",
    exact: true,
    name: "Default",
    component: CountryDefault,
  },
  {
    path: "/Masters/Country/Form",
    exact: true,
    name: "Default",
    component: CountryCreate,
  },
  {
    path: "/Masters/City",
    exact: true,
    name: "Default",
    component: CityDefault,
  },
  {
    path: "/Masters/City/Form",
    exact: true,
    name: "Default",
    component: CityCreate,
  },
  {
    path: "/Masters/Package",
    exact: true,
    name: "Default",
    component: PackageDefault,
  },
  {
    path: "/Masters/Package/Form",
    exact: true,
    name: "Default",
    component: PackageCreate,
  },
  {
    path: "/Masters/Package/PurchaseOrders",
    exact: true,
    name: "Default",
    component: PackagePO,
  },
  {
    path: "/Masters/Employee",
    exact: true,
    name: "Default",
    component: EmpDefault,
  },
  {
    path: "/Masters/Employee/Form",
    exact: true,
    name: "Default",
    component: EmpCreate,
  },
  {
    path: "/Masters/UOM",
    exact: true,
    name: "Default",
    component: UOMDefault,
  },
  {
    path: "/Masters/UOM/Form",
    exact: true,
    name: "Default",
    component: UOMCreate,
  },
  {
    path: "/Masters/Company",
    exact: true,
    name: "Default",
    component: CompanyDefault,
  },
  {
    path: "/Masters/Company/Form",
    exact: true,
    name: "Default",
    component: CompanyCreate,
  },
  {
    path: "/Masters/Region",
    exact: true,
    name: "Default",
    component: RegionDefault,
  },
  {
    path: "/Masters/Region/Form",
    exact: true,
    name: "Default",
    component: RegionCreate,
  },
  {
    path: "/Masters/Address",
    exact: true,
    name: "Default",
    component: AddressDefault,
  },
  {
    path: "/Masters/Address/Form",
    exact: true,
    name: "Default",
    component: AddressCreate,
  },
  {
    path: "/Masters/Vendor",
    exact: true,
    name: "Default",
    component: VendorDefault,
  },
  {
    path: "/Masters/Vendor/Form",
    exact: true,
    name: "Default",
    component: VendorCreate,
  },
  {
    path: "/Masters/Currency",
    exact: true,
    name: "Default",
    component: CurrencyDefault,
  },
  {
    path: "/Masters/Currency/Form",
    exact: true,
    name: "Default",
    component: CurrencyCreate,
  },
  {
    path: "/Masters/CurrencyRate",
    exact: true,
    name: "Default",
    component: CurrencyRateDefault,
  },
  {
    path: "/Masters/CurrencyRate/Form",
    exact: true,
    name: "Default",
    component: CurrencyRateCreate,
  },
  {
    path: "/Masters/Level",
    exact: true,
    name: "Default",
    component: LevelDefault,
  },
  {
    path: "/Masters/Level/Form",
    exact: true,
    name: "Default",
    component: LevelCreate,
  },
  {
    path: "/Masters/Designation",
    exact: true,
    name: "Default",
    component: DesignationDefault,
  },
  {
    path: "/Masters/Designation/Form",
    exact: true,
    name: "Default",
    component: DesignationCreate,
  },
  {
    path: "/Masters/EmploymentType",
    exact: true,
    name: "Default",
    component: EmploymentTypeDefault,
  },
  {
    path: "/Masters/EmploymentType/Form",
    exact: true,
    name: "Default",
    component: EmploymentTypeCreate,
  },
  {
    path: "/SystemSettings/IdentCode",
    exact: true,
    name: "Default",
    component: IdentDefault,
  },
  {
    path: "/SystemSettings/IdentCode/Form",
    exact: true,
    name: "Default",
    component: IdentCreate,
  },
  {
    path: "/SystemSettings/ProjectSettings",
    exact: true,
    name: "Default",
    component: ProjectSettingsDefault,
  },
  {
    path: "/SystemSettings/ProjectSettings/DimensionRules",
    exact: true,
    name: "Default",
    component: DimensionRules,
  },
  {
    path: "/SystemSettings/ProjectSettings/ExtraPieceRules",
    exact: true,
    name: "Default",
    component: ExtraPieceRules,
  },
  {
    path: "/SystemSettings/ProjectSettings/PipingExtraPieceRules",
    exact: true,
    name: "Default",
    component: PipingExtraPieceRules,
  },
  {
    path: "/SystemSettings/ProjectSettings/PLTemplateRules",
    exact: true,
    name: "Default",
    component: PLTemplateRules,
  },
  {
    path: "/SystemSettings/ProjectSettings/PLGenerationRules",
    exact: true,
    name: "Default",
    component: PLGenerationRules,
  },
  {
    path: "/SystemSettings/ProjectSettings/HybridRules",
    exact: true,
    name: "Default",
    component: HybridRules,
  },
  {
    path: "/SystemSettings/ProjectSettings/CurrencyRules",
    exact: true,
    name: "Default",
    component: CurrencyRules,
  },
  {
    path: "/SystemSettings/POPosition",
    exact: true,
    name: "Default",
    component: POPosDefault,
  },
  {
    path: "/SystemSettings/POPosition/Form",
    exact: true,
    name: "Default",
    component: POPosCreate,
  },
  {
    path: "/SystemSettings/NameOfGoods",
    exact: true,
    name: "Default",
    component: NameOfGoods,
  },
  {
    path: "/SystemSettings/NameOfGoods/Form",
    exact: true,
    name: "Default",
    component: NameOfGoodsForm,
  },
  {
    path: "/SystemSettings/MailConfigurations",
    exact: true,
    name: "Default",
    component: MailConfigurationsDefault,
  },
  {
    path: "/SystemSettings/MailConfigurations/Form",
    exact: true,
    name: "Default",
    component: MailConfigurationsCreate,
  },
  {
    path: "/SystemSettings/QCType",
    exact: true,
    name: "Default",
    component: QCType,
  },
  {
    path: "/SystemSettings/QCType/Form",
    exact: true,
    name: "Default",
    component: QCTypeCreate,
  },
  {
    path: "/SystemSettings/QCMethod",
    exact: true,
    name: "Default",
    component: QCMethod,
  },
  {
    path: "/SystemSettings/QCMethod/Form",
    exact: true,
    name: "Default",
    component: QCMethodCreate,
  },
  {
    path: "/SystemSettings/QCPainting",
    exact: true,
    name: "Default",
    component: QCPainting,
  },
  {
    path: "/SystemSettings/QCPainting/Form",
    exact: true,
    name: "Default",
    component: QCPaintingCreate,
  },
  {
    path: "/Purchase/default",
    exact: true,
    name: "Default",
    component: PurchaseDefault,
  },
  {
    path: "/Purchase/default/Form",
    exact: true,
    name: "Default",
    component: PurchaseCreate,
  },
  {
    path: "/UpdateMarianDetails",
    exact: true,
    name: "Default",
    component: UpdatePODefault,
  },
  {
    path: "/UpdateMarianDetails/Form",
    exact: true,
    name: "Default",
    component: UpdatePOCreate,
  },
  {
    path: "/ProgressReport",
    exact: true,
    name: "Default",
    component: ProgressReport,
  },
  {
    path: "/ProgressReport/Form",
    exact: true,
    name: "Default",
    component: ProgressReportCreate,
  },
  {
    path: "/Project/default",
    exact: true,
    name: "Default",
    component: ProjectDefault,
  },
  {
    path: "/Project/default/Form",
    exact: true,
    name: "Default",
    component: ProjectCreate,
  },
  {
    path: "/SystemSettings/Sequence",
    exact: true,
    name: "Default",
    component: SequenceDefault,
  },
  {
    path: "/SystemSettings/Sequence/Form",
    exact: true,
    name: "Default",
    component: SequenceCreate,
  },
  {
    path: "/SystemSettings/EditSequence",
    exact: true,
    name: "Default",
    component: EditSequence,
  },
  {
    path: "/SystemSettings/EditSequence/Form",
    exact: true,
    name: "Default",
    component: EditSequenceForm,
  },
  {
    path: "/PackingList/default",
    exact: true,
    name: "Default",
    component: PackingListDefault,
  },
  {
    path: "/PackingList/View",
    exact: true,
    name: "Default",
    component: PackingListView,
  },
  {
    path: "/PackingList/Form",
    exact: true,
    name: "Default",
    component: PackingListEdit,
  },
  {
    path: "/PackingList/Form/revision",
    exact: true,
    name: "Default",
    component: PackingListEdit,
  },
  {
    path: "/PackingList/default/revision",
    exact: true,
    name: "Default",
    component: PackingListDefault,
  },
  {
    path: "/Documents/default",
    exact: true,
    name: "Default",
    component: DMSDefault,
  },
  {
    path: "/Accounts/Users",
    exact: true,
    name: "Default",
    component: UserDefault,
  },
  {
    path: "/Accounts/Users/Form",
    exact: true,
    name: "Default",
    component: UserCreate,
  },
  {
    path: "/Accounts/UserGroups",
    exact: true,
    name: "Default",
    component: UserGroupDefault,
  },
  {
    path: "/Accounts/UserGroups/Form",
    exact: true,
    name: "Default",
    component: UserGroupCreate,
  },
  {
    path: "/User/Profile",
    exact: true,
    name: "Default",
    component: UserProfile,
  },
  {
    path: "/MaterialStatus/Form",
    exact: true,
    name: "Default",
    component: AllPackagesEdit,
  },
  {
    path: "/MaterialStatus/ShippedStatus",
    exact: true,
    name: "Default",
    component: ShippedStatus,
  },
  {
    path: "/MaterialStatus/OnsiteStatus",
    exact: true,
    name: "Default",
    component: OnsiteStatus,
  },
  {
    path: "/Reports/default",
    exact: true,
    name: "Default",
    component: ReportsDefault,
  },
  {
    path: "/Reports/PackingListReport",
    exact: true,
    name: "Default",
    component: PLReport,
  },
  {
    path: "/Reports/MarkwiseCumulativeReport",
    exact: true,
    name: "Default",
    component: MarkCummReport,
  },
  {
    path: "/Reports/MarkwiseReport",
    exact: true,
    name: "Default",
    component: MarkwiseReport,
  },
  {
    path: "/Reports/PackagewiseReport",
    exact: true,
    name: "Default",
    component: PackageReport,
  },
  {
    path: "/Reports/StructurewiseReport",
    exact: true,
    name: "Default",
    component: StructureReport,
  },
  {
    path: "/Reports/GroupStructurewiseReport",
    exact: true,
    name: "Default",
    component: GroupStructureReport,
  },
  {
    path: "/Reports/MWPErectionReport",
    exact: true,
    name: "Default",
    component: MWPErectionReport,
  },
  {
    path: "/Reports/DetailFigureReport",
    exact: true,
    name: "Default",
    component: DetailFigureReport,
  },
  {
    path: "/Reports/POPositionwiseReport",
    exact: true,
    name: "Default",
    component: PurchaseOrderwiseReport,
  },
  {
    path: "/Reports/IdentCodewiseReport",
    exact: true,
    name: "Default",
    component: IdentCodewiseReport,
  },
  {
    path: "/Reports/SummaryReport",
    exact: true,
    name: "Default",
    component: SummaryReport,
  },
  {
    path: "/Reports/ForecastReport",
    exact: true,
    name: "Default",
    component: ForecastReport,
  },
  {
    path: "/Reports/MarianReport",
    exact: true,
    name: "Default",
    component: MarianReport,
  },
  {
    path: "/Reports/MarianReleaseReport",
    exact: true,
    name: "Default",
    component: MarianReleaseReport,
  },
  {
    path: "/Reports/TraceabilityReport",
    exact: true,
    name: "Default",
    component: TraceabilityReport,
  },
  {
    path: "/Reports/FastenersReport",
    exact: true,
    name: "Default",
    component: FastenersReport,
  },
  // {
  //   path: "/Reports/LDSummaryReport",
  //   exact: true,
  //   name: "Default",
  //   component: LDSummaryReport,
  // },
  // {
  //   path: "/Reports/LDDetailedReport",
  //   exact: true,
  //   name: "Default",
  //   component: LDDetailedReport,
  // },
  {
    path: "/Reports/PipingPackingListReport",
    exact: true,
    name: "Default",
    component: PipingPackingListReport,
  },
  {
    path: "/Reports/SummaryPOReport",
    exact: true,
    name: "Default",
    component: SummaryPOReport,
  },
  {
    path: "/Reports/PipingPOPositionwiseReport",
    exact: true,
    name: "Default",
    component: PipingPOPositionwiseReport,
  },
  {
    path: "/Reports/PipingMTCwiseReport",
    exact: true,
    name: "Default",
    component: PipingMTCwiseReport,
  },
  {
    path: "/Reports/PipingMTCDetailsReport",
    exact: true,
    name: "Default",
    component: PipingMTCDetailsReport,
  },
  {
    path: "/SystemSettings/default",
    exact: true,
    name: "Default",
    component: SysSettingsDefault,
  },
  {
    path: "/SystemSettings/ApprovalRules",
    exact: true,
    name: "Default",
    component: ApprovalRules,
  },
  {
    path: "/SystemSettings/ApprovalRules/Form",
    exact: true,
    name: "Default",
    component: ApprovalRulesCreate,
  },
  {
    path: "/SystemSettings/Reasons",
    exact: true,
    name: "Default",
    component: Reasons,
  },
  {
    path: "/SystemSettings/Reasons/Form",
    exact: true,
    name: "Default",
    component: ReasonsCreate,
  },
  {
    path: "/Masters/default",
    exact: true,
    name: "Default",
    component: MastersDefault,
  },
  {
    path: "/CompareShopDrawings",
    exact: true,
    name: "Default",
    component: CompareShopDrawings,
  },
  {
    path: "/HybridShopDrawing",
    exact: true,
    name: "Default",
    component: HybridMBL,
  },
  {
    path: "/MBLSummaryReport",
    exact: true,
    name: "Default",
    component: MBLSummary,
  },
  {
    path: "/Forecasting/default",
    exact: true,
    name: "Default",
    component: Forecasting,
  },
  {
    path: "/Forecasting/default/Form",
    exact: true,
    name: "Default",
    component: ForecastingCreate,
  },
  {
    path: "/IRC",
    exact: true,
    name: "Default",
    component: IRC,
  },
  {
    path: "/IRC/Form",
    exact: true,
    name: "Default",
    component: IRCCreate,
  },
  {
    path: "/SystemSettings/NotificationSettings",
    exact: true,
    name: "Default",
    component: NotifySettingsDefault,
  },
  {
    path: "/SystemSettings/NotificationSettings/Form",
    exact: true,
    name: "Default",
    component: NotifySettingsCreate,
  },
  {
    path: "/SystemSettings/NotificationFollowers",
    exact: true,
    name: "Default",
    component: NotifyFollowersDefault,
  },
  {
    path: "/SystemSettings/NotificationFollowers/Form",
    exact: true,
    name: "Default",
    component: NotifyFollowersCreate,
  },
  {
    path: "/SystemSettings/ProjectSettings/Marks",
    exact: true,
    name: "Default",
    component: Marks,
  },
  {
    path: "/SystemSettings/ProjectSettings/Marks/Form",
    exact: true,
    name: "Default",
    component: MarksCreate,
  },
  {
    path: "/SystemSettings/ProjectSettings/Structures",
    exact: true,
    name: "Default",
    component: Structures,
  },
  {
    path: "/SystemSettings/ProjectSettings/Structures/Form",
    exact: true,
    name: "Default",
    component: StructuresCreate,
  },
  {
    path: "/Prism",
    exact: true,
    name: "Default",
    component: PrismDefault,
  },
  {
    path: "/SystemSettings/POSettings",
    exact: true,
    name: "Default",
    component: ProjectSettingsDefault,
  },
  {
    path: "/SystemSettings/POSettings/DimensionRules",
    exact: true,
    name: "Default",
    component: DimensionRules,
  },
  {
    path: "/SystemSettings/POSettings/ExtraPieceRules",
    exact: true,
    name: "Default",
    component: ExtraPieceRules,
  },
  {
    path: "/SystemSettings/POSettings/PipingExtraPieceRules",
    exact: true,
    name: "Default",
    component: PipingExtraPieceRules,
  },
  {
    path: "/SystemSettings/POSettings/PLTemplateRules",
    exact: true,
    name: "Default",
    component: PLTemplateRules,
  },
  {
    path: "/SystemSettings/POSettings/PLGenerationRules",
    exact: true,
    name: "Default",
    component: PLGenerationRules,
  },
  {
    path: "/SystemSettings/POSettings/HybridRules",
    exact: true,
    name: "Default",
    component: HybridRules,
  },
  {
    path: "/SystemSettings/POSettings/Marks",
    exact: true,
    name: "Default",
    component: Marks,
  },
  {
    path: "/SystemSettings/POSettings/Marks/Form",
    exact: true,
    name: "Default",
    component: MarksCreate,
  },
  {
    path: "/SystemSettings/POSettings/Structures",
    exact: true,
    name: "Default",
    component: Structures,
  },
  {
    path: "/SystemSettings/POSettings/Structures/Form",
    exact: true,
    name: "Default",
    component: StructuresCreate,
  },
  {
    path: "/LatestMBL",
    exact: true,
    name: "Default",
    component: LatestMBL,
  },
  {
    path: "/LatestMBL/Form",
    exact: true,
    name: "Default",
    component: LatestMBLCreate,
  },
  {
    path: "/LatestMBL/view",
    exact: true,
    name: "Default",
    component: LatestMBLView,
  },
];

export default routes;
