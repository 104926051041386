import * as actionTypes from '../actions';

const initialState = {
	sdMain: {},
	resData: null,
	bc: null,
	rev_bc: null,
	isRevision: false,
	sdForm: null,
	sd_create: {
		disableSubmitBtn: true,
		disableNextBtn: false,
		draftActive: true,
		submitActive: false,
		showRevModal: false,
		isSameRevision: false,
		respFile: false,
		showNextModal: false,
		revFile: null,
		showValidationModal: false,
		loading: true,
		isOverlay: false,
		draftDetails: {
			showDetailsForm: true,
			showUploadForm: false,
			showAttachementTab: false,
			showReject: false,
			activeClass: 0,
			formData: null,
			redirectTab: false,
		},
		createFormDetails: {
			form: {},
			showUploadForm: false,
			editMode: false,
			poList: [],
			poObj: {},
			isFormValid: false,
			uploadedFile: null,
			isPOInValid: false,
		},
	},
};

const sdReducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.SDMAIN_SELID:
			return {
				...state,
				sdMain: { ...state.sdMain, id: action.id, mode: action.mode, readOnly: action.readOnly },
			};
		case actionTypes.SD_CREATE:
			return {
				...state,
				sd_create: action.value,
			};
		case actionTypes.SD_DRAFT:
			return {
				...state,
				sd_create: { ...state.sd_create, draftDetails: action.value },
			};
		case actionTypes.SD_CREATEFORM:
			return {
				...state,
				sd_create: { ...state.sd_create, createFormDetails: action.value },
			};
		case actionTypes.SD_SET_RESPONSE_DATA:
			return {
				...state,
				resData: action.data,
			};
		case actionTypes.SD_SET_TO_INITIALSTATE:
			return {
				...state,
				sd_create: initialState.sd_create,
				sdForm: null,
			};
		case actionTypes.SD_SET_BC:
			return {
				...state,
				bc: action.bc,
			};
		case actionTypes.SD_SET_REV_BC:
			return {
				...state,
				rev_bc: action.bc,
			};
		case actionTypes.SD_SET_ISREVISION:
			return {
				...state,
				isRevision: action.value,
			};
		case actionTypes.SD_SET_FORM:
			return {
				...state,
				sdForm: action.value,
			};
		default:
			return state;
	}
};

export default sdReducer;
