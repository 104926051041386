import isUndefined from 'lodash-es/isUndefined';
import isArray from 'lodash-es/isArray';
import toArray from 'lodash-es/toArray';
import isObject from 'lodash-es/isObject';
import isNull from 'lodash-es/isNull';
import findIndex from 'lodash-es/findIndex';
import extend from 'lodash-es/extend';
import each from 'lodash-es/each';
import includes from 'lodash-es/includes';
import isString from 'lodash-es/isString';
import merge from 'lodash-es/merge';
import keys from 'lodash-es/keys';
import replace from 'lodash-es/replace';
import pick from 'lodash-es/pick';
import cloneDeep from 'lodash-es/cloneDeep';
import omit from 'lodash-es/omit';
import isEmpty from 'lodash-es/isEmpty';
import indexOf from 'lodash-es/indexOf';
import isNumber from 'lodash-es/isNumber';
import has from 'lodash-es/has';
import filter from 'lodash-es/filter';
import map from 'lodash-es/map';
import sortBy from 'lodash-es/sortBy';
import isEqual from 'lodash-es/isEqual';

export const isUndefinedOrNull = function(val) {
	return isUndefined(val) || isNull(val);
};

export const toArrayIfObject = function(obj) {
	return isObject(obj) && !isArray(obj) ? [obj] : obj;
};

export const isHtml = function(str) {
	return /<[a-z/][\s\S]*>/i.test(str); // true
};

export const isStringJSON = function(str) {
	try {
		JSON.parse(str);
	} catch (e) {
		return false;
	}
	return true;
};

export const findAndReplace = function(array, replace, callback) {
	if (array && replace) {
		if (isArray(replace)) {
			return replace.map((obj) => {
				return this.findAndReplace(array, obj, callback);
			});
		} else {
			let index = findIndex(array, callback ? (o) => callback(o, replace) : replace);
			if (index > -1) {
				return array.splice(index, 1, replace);
			}
		}
	}
};
export const findUpdateOrCreate = function(array, replace, callback) {
	let replaced = this.findAndReplace(array, replace, callback);
	if (replaced === undefined) {
		return array.push(replace);
	}
};
export const findAndMerge = function(array, replace, callback) {
	if (array && replace) {
		if (isArray(replace)) {
			return replace.map((obj) => {
				return this.findAndMerge(array, obj, callback);
			});
		} else {
			let index = findIndex(array, callback ? (o) => callback(o, replace) : replace);
			if (index > -1) {
				return array.splice(index, 1, extend(array[index], replace));
			}
		}
	}
};
export const findAndDelete = function(array, find, callback) {
	if (array && find) {
		if (isArray(find)) {
			return find.map((obj) => {
				return this.findAndDelete(array, obj, callback);
			});
		} else {
			let index = -1;
			if (isObject(array[0])) {
				index = findIndex(array, callback ? (o) => callback(o) : find);
			} else {
				index = indexOf(array, callback ? (o) => callback(o) : find);
			}

			if (index > -1) {
				return array.splice(index, 1);
			}
		}
	}
};
export const mergeClassProperty = function(target, ...mixins) {
	mixins.forEach((mixin) => {
		each(Object.getPrototypeOf(mixin.prototype), (prop, key) => {
			if (!key.match(/^(?:constructor|prototype|arguments|caller|name|bind|call|apply|toString|length)$/))
				Object.defineProperty(target, prop, Object.getOwnPropertyDescriptor(prop, key));
		});
	});
};

export {
	isUndefined,
	isArray,
	toArray,
	isEmpty,
	isNumber,
	indexOf,
	each,
	keys,
	isObject,
	isString,
	isNull,
	includes,
	findIndex,
	extend,
	merge,
	replace,
	pick,
	has,
	cloneDeep,
	omit,
	filter,
	map,
	sortBy,
	isEqual,
};
